import parseFAQBanner from 'components/FaqBanner/parsers';
import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';
import parseTwoColumnsSymptomList from 'components/TwoColumnSymptomsIllustrations/parsers';

const parseInteractiveImage = (bodyItem) => ({
  structure: bodyItem.structure,
  properties: {
    ...bodyItem.properties,
    openedIndex: bodyItem.properties.interactiveImageSymptomCards.findIndex(
      ({ properties: { isOpened } }) => isOpened
    ),
    interactiveImageSymptomCards: bodyItem.properties.interactiveImageSymptomCards.reduce(
      (acc, { properties: curr }) => {
        const card = acc.find((item) => item.properties.symptomCardType === curr.symptomCardType);
        if (!card) {
          const newCurr = {
            properties: {
              ...curr,
              cardContent: [
                {
                  titleOnCard: curr.titleOnCard,
                  symptomCardDescription: curr.symptomCardDescription,
                },
              ],
            },
          };

          return acc.concat([newCurr]);
        }
        card.properties.cardContent.push({
          titleOnCard: curr.titleOnCard,
          symptomCardDescription: curr.symptomCardDescription,
        });

        return acc;
      },
      [] as { properties: CampaignPageTypes.ISymptomCardProps }[]
    ),
  },
});

const parseCampaignBody = (campaignBody) =>
  campaignBody.map((bodyItem) => {
    switch (bodyItem.structure) {
      case 'Interactive image':
        return parseInteractiveImage(bodyItem);
      case 'Symptoms List':
        return {
          properties: parseSymptomsList(bodyItem.properties),
          structure: bodyItem.structure,
        };
      case 'Two Column Symptoms List':
        return {
          properties: parseTwoColumnsSymptomList(bodyItem.properties),
          structure: bodyItem.structure,
        };
      case 'FAQ Banner':
        return {
          properties: parseFAQBanner(bodyItem.properties),
          structure: bodyItem.structure,
        };
      default:
        return bodyItem;
    }
  });

export default parseCampaignBody;
