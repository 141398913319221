import CampaignPage from "gatsby-theme-nurofen/src/templates/CampaignPage/CampaignPage"
import { graphql } from "gatsby"

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $articleTagId: Int
    $featuredProductsLinks: [String]
    $pageBannerImageSize: Int = 1200
    $pageIdRegex: String
  ) {
    featuredArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $articleTagId } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    featuredProducts: allUmbracoProduct(
      filter: { link: { in: $featuredProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    allCampaignPage(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        ...FragmentSeo
        lang
        pageName
        campaignBanner {
          properties {
            ...FragmentBannerNZ
          }
        }
        campaignBody {
          structure
          properties {
            ...FragmentFeaturedArticles
            ...FragmentFeaturedProducts
            ...FragmentVideoBlock
            ...FragmentPromoBanner
            ...FragmentInteractiveImage
            ...FragmentInfoCards
            ...FragmentSymptomsIllustrations
            ...FragmentFAQBanner
            ...FragmentTwoColumnSymptomsIllustrations
            text
          }
        }
        typeOfProductCarousel
        campaignHeader {
          properties {
            ...FragmentHeaderCustom
          }
        }
      }
    }
    pageLangs: allCampaignPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`

export default CampaignPage
