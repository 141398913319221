import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import { IInteractiveImageProps } from 'components/InteractiveImage/model';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import CustomTabs from 'components/common/CustomTabs';

import 'components/InteractiveImage/InteractiveImage.scss';

const InteractiveImage: FC<IInteractiveImageProps> = ({
  title,
  interactiveTabs,
  img,
  alt,
  imgType,
  openedIndex,
  bgColor,
  interactiveImageAnchor,
}) => {
  const [tabIndex, setTabIndex] = useState<number>(
    openedIndex && openedIndex > 0 ? openedIndex : 0
  );

  const handleSelect = (index) => {
    setTabIndex(index);
  };

  return (
    <div
      className="campaign-anchor-section"
      {...(interactiveImageAnchor ? { id: interactiveImageAnchor } : {})}
    >
      <div
        className={`interactive-image interactive-image--${imgType}`}
        style={
          bgColor
            ? ({
                backgroundColor: `var(--${bgColor})`,
              } as React.CSSProperties)
            : undefined
        }
      >
        <Container fluid>
          <strong className="interactive-image__title">{title}</strong>
          <div className="interactive-image__frame">
            <div className="interactive-image__picture">
              <UniversalImage img={img} alt={alt} />
            </div>
            <div className="interactive-image__tabs">
              <CustomTabs
                onSelectCallback={handleSelect}
                selectedIndex={tabIndex}
                items={interactiveTabs.map(
                  ({ properties: { titleOnImage, cardContent, symptomCardType } }) => ({
                    tabTitle: {
                      name: titleOnImage,
                      ariaLabel: titleOnImage,
                    },
                    content: cardContent.map(({ titleOnCard, symptomCardDescription }) => (
                      <div className="interactive-image__tab-slide" key={titleOnCard}>
                        <strong className="interactive-image__tab-slide-title">
                          {titleOnCard}
                        </strong>
                        <DangerouslySetInnerHtml
                          html={symptomCardDescription}
                          className="interactive-image__tab-slide-text"
                        />
                      </div>
                    )),
                    className: symptomCardType!.replace(/\s+/g, '-'),
                  })
                )}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InteractiveImage;

export const query = graphql`
  fragment FragmentInteractiveImage on IInteractiveImage {
    interactiveImageTitle
    interactiveImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    interactiveImageType
    interactiveImageSymptomCards {
      properties {
        titleOnImage
        titleOnCard
        symptomCardDescription
        symptomCardType
        isOpened
      }
    }
    interactiveImageBgColor {
      label
    }
    interactiveImageAnchor
    interactiveImageAlt
  }
`;
