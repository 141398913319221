import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import './TileItem.scss';

const TileItem: FC<CampaignPageTypes.IInfoCard> = ({
  properties: { infoCardImage, infoCardImageAlt, infoCardText },
}) => {
  const classes = classNames({
    'image--svg': infoCardImage.svg?.content,
  });

  return (
    <li className="tile-item">
      <div className="tile-item__image">
        <UniversalImage
          img={infoCardImage}
          imageAlt={infoCardImageAlt}
          wrapperClassName={classes}
        />
      </div>
      <DangerouslySetInnerHtml html={infoCardText} className="tile-item__text" />
    </li>
  );
};

export default TileItem;
