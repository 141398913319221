import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SymptomsIllustrations from 'components/SymptomsIllustrations';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPropsTwoColumnSymptomsIllustrations } from './model';

import './TwoColumnSymptomsIllustrations.scss';

const TwoColumnSymptomsIllustrations: FC<IPropsTwoColumnSymptomsIllustrations> = ({
  symptomsSection,
  symptomsSectionTitle,
  symptomsSectionBgColor,
}) => (
  <div
    className="two-column-symptoms-lists-section"
    style={
      symptomsSectionBgColor
        ? ({
            backgroundColor: `var(--${symptomsSectionBgColor[0].properties.colorPicker?.label})`,
          } as React.CSSProperties)
        : undefined
    }
  >
    <Container fluid>
      <Row>
        <Col md={12}>
          {symptomsSectionTitle ? (
            <DangerouslySetInnerHtml html={symptomsSectionTitle} className="section-title" />
          ) : null}
        </Col>
      </Row>
      <Row>
        {symptomsSection.map((section, index) => (
          <Col md={6} key={section?.headingGroup?.symptomsDescription || index}>
            <SymptomsIllustrations {...section} />
          </Col>
        ))}
      </Row>
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentTwoColumnSymptomsIllustrations on ITwoColumnSymptomsIllustrations {
    symptomsSection {
      properties {
        ...FragmentSymptomsIllustrations
      }
    }
    symptomsSectionTitle
    symptomsSectionBgColor {
      ...FragmentColorProps
    }
  }
`;

export default TwoColumnSymptomsIllustrations;
