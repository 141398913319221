import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import TileItem from 'components/CardsWithImages/TileItem';

import './CardsWithImages.scss';

const CardsWithImages: FC<CampaignPageTypes.IInfoCards> = ({
  infoCardsTitle,
  infoCardsList,
  infoCardsAnchor,
}) => (
  <div className="campaign-anchor-section" {...(infoCardsAnchor ? { id: infoCardsAnchor } : {})}>
    <div className="card-with-image">
      <Container fluid>
        <strong className="card-with-image__title">{infoCardsTitle}</strong>
        <ul className="card-with-image__tile-list">
          {infoCardsList?.map((item) => (
            <TileItem {...item} />
          ))}
        </ul>
      </Container>
    </div>
  </div>
);

export default CardsWithImages;

export const query = graphql`
  fragment FragmentInfoCards on IInfoCards {
    infoCardsTitle
    infoCardsList {
      properties {
        infoCardImage {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 310) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
        infoCardImageAlt
        infoCardText
      }
    }
    infoCardsAnchor
  }
`;
