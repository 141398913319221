import React, { FC } from 'react';

import NFButton from 'components/common/NFButton';
import { IPromoImageProps } from 'components/PromoBanner/PromoImage/model';
import UniversalImage from 'components/common/UniversalImage';

import './PromoImage.scss';

const PromoImage: FC<IPromoImageProps> = ({ img, alt, btn }) => {
  return (
    <div className="promo-product-banner__image">
      <div className="promo-product-banner__image-frame">
        <UniversalImage img={img} imageAlt={alt} />
      </div>
      <div className="btn-holder">
        <NFButton {...btn} />
      </div>
    </div>
  );
};

export default PromoImage;
