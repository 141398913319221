import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';

import {
  IPropsTwoColumnSymptomsIllustrations,
  IUmbracoTwoColumnSymptomsIllustrationsProperties,
} from './model';

const parseTwoColumnsSymptomList = (
  param: IUmbracoTwoColumnSymptomsIllustrationsProperties
): IPropsTwoColumnSymptomsIllustrations => ({
  symptomsSection: param.symptomsSection.map(({ properties }) => parseSymptomsList(properties)),
  symptomsSectionTitle: param?.symptomsSectionTitle,
  symptomsSectionBgColor: param?.symptomsSectionBgColor,
});

export default parseTwoColumnsSymptomList;
