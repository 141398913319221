import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IAdvantagesListProps } from './models';

import './AdvantagesList.scss';

const AdvantagesList: FC<IAdvantagesListProps> = ({ seoTitle, advantagesItem }) => (
  <div className="advantages-list" data-text="AdvantagesList">
    <h2 className="advantages-list__title sr-only">{seoTitle}</h2>
    <Container className="advantages-list__wrapper">
      {advantagesItem.map(({ properties: { text, icon, iconAlt } }) => (
        <div key={text} className="advantages-list__item">
          {icon ? (
            <UniversalImage
              imageAlt={iconAlt}
              img={icon}
              objectFitData={{ objectFit: 'contain' }}
              wrapperClassName="advantages-list__icon"
            />
          ) : null}
          <DangerouslySetInnerHtml className="advantages-list__text" html={text} />
        </div>
      ))}
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentAdvantagesList on IAdvantagesList {
    seoTitle
    advantagesItem {
      properties {
        text
        iconAlt
        icon {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
      }
    }
  }
`;

export default AdvantagesList;
