import React, { FC } from 'react';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IPromoItemProps } from 'components/PromoBanner/PromoItem/model';
import './PromoItem.scss';

const PromoItem: FC<IPromoItemProps> = ({ img, alt, title, description, disclaimer }) => (
  <li className="promo-product-banner__item">
    <div className="promo-product-banner__item-caption">
      <div className="promo-product-banner__item-caption-icon">
        <UniversalImage img={img} imageAlt={alt} />
      </div>
      <strong className="promo-product-banner__item-caption-title">{title}</strong>
    </div>
    <DangerouslySetInnerHtml className="promo-product-banner__item-text" html={description} />
    <DangerouslySetInnerHtml className="promo-product-banner__item-disclaimer" html={disclaimer} />
  </li>
);

export default PromoItem;
